import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container, Pagination, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './News.css';
import { Helmet } from 'react-helmet';
import CardComponent from './CardComponent';
import { checkAndUpdateLanguage } from '../i18n/i18n';

// Import all news data files
import newsDataEn from '../data/newsData_en.json';
import newsDataSc from '../data/newsData_sc.json';
import newsDataTc from '../data/newsData_tc.json';
import newsDataTh from '../data/newsData_th.json';


const News = () => {
  const { t, i18n } = useTranslation();
  const [newsData, setNewsData] = useState([]);
  const [error, setError] = useState(null);
  const newsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [showBackToTop, setShowBackToTop] = useState(false);

  console.log('News component rendered. Current language:', i18n.language);

  const loadNewsData = useCallback(() => {
    try {
      const language = checkAndUpdateLanguage(i18n.language);
      let data;

      console.log('Loading news data for language:', language);

      switch (language) {
        case 'en':
          data = newsDataEn;
          break;
        case 'sc':
          data = newsDataSc;
          break;
        case 'tc':
          data = newsDataTc;
          break;
        case 'th':
          data = newsDataTh;
          break;

        default:
          data = newsDataEn; // fallback to English
      }

      console.log('Data loaded:', data);
      setNewsData(data);
      setError(null);
    } catch (err) {
      console.error('Error loading news data:', err);
      setError(err);
    }
  }, [i18n.language]);

  useEffect(() => {
    console.log('useEffect triggered');
    loadNewsData();

    const handleLanguageChange = () => {
      console.log('Language change event detected');
      loadNewsData();
    };

    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [i18n.language, loadNewsData]);

  const handleFilterChange = useCallback((event) => {
    setCurrentFilter(event.target.value);
    setCurrentPage(1);
  }, []);

  const filteredNewsArticles = useMemo(() => {
    return currentFilter === 'All'
      ? newsData
      : newsData.filter((article) => article.topic === currentFilter);
  }, [currentFilter, newsData]);

  const pageCount = Math.ceil(filteredNewsArticles.length / newsPerPage);
  const currentNews = filteredNewsArticles.slice(
    (currentPage - 1) * newsPerPage,
    currentPage * newsPerPage
  );

  const handlePageClick = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const paginationItems = useMemo(() => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageClick(number)}
          aria-label={`Go to page ${number}`}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }, [pageCount, currentPage, handlePageClick]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <title>HONGKONG.AI - A.I. Social Impact</title>
        <meta name="description" content="Stay up-to-date with the latest technology news, covering AI, robotics, and more." />
        <link rel="canonical" href="https://www.hongkong.ai/news" />

        {/* OpenGraph tags */}
        <meta property="og:title" content="HONGKONG.AI - Your AI Hub in Hong Kong" />
        <meta property="og:description" content="Stay up-to-date with the latest technology news, covering AI, robotics, and more." />
        <meta property="og:image" content="https://www.hongkong.ai/HK_1920x1080.jpg" />
        <meta property="og:url" content="https://www.hongkong.ai/news" />
        <meta property="og:type" content="website" />

        <link rel="apple-touch-icon" href="https://www.hongkong.ai/HK_1920x1080.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        {/* JSON-LD structured data for news collection */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "mainEntity": {
              "@type": "ItemList",
              "itemListElement": currentNews.map((article, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "url": `https://www.hongkong.ai/news/${article.id}`,
                "name": article.title,
                "description": article.text.substring(0, 200)
              }))
            }
          })}
        </script>
      </Helmet>

      <Container fluid className="news-container">
        <div className="separator" />
        <div className="separator" />
        <h1 className="text-left">{t('A.I. Social Impact')}</h1>
        <div className="separator" />

        <Row className="align-items-center justify-content-end">
          <Col xs={12} sm={4} md={3} lg={2} className="mb-2 pe-3">
            <Form>
              <Form.Group controlId="topicFilter">
                <Form.Label className="visually-hidden">Filter by topic</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  value={currentFilter}
                  onChange={handleFilterChange}
                  className="form-select-sm custom-select"
                >
                  <option value="All">{t('All Topics')}</option>
                  {[...new Set(newsData.map((article) => article.topic))].map((topic) => (
                    <option key={topic} value={topic}>
                      {topic}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <br />
        <br />
        <br />

        <Row className="news-container">
          {currentNews.map((article) => (
            <Col xs={12} sm={6} md={4} lg={3} className="mb-4" key={article.title}>
              <CardComponent
                className="card-hover"
                image={article.image}
                date={article.date}
                title={article.title}
                text={article.text}
                photoCredit={article.photoCredit}
                url={article.url}
                alt={`${article.title} - ${article.text.substring(0, 100)}...`}
                topic={article.topic}
                credit={article.Credit}
              />
              {/* JSON-LD structured data for individual article */}
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "NewsArticle",
                  "headline": article.title,
                  "datePublished": article.date,
                  "description": article.text.substring(0, 200),
                  "image": article.image,
                  "url": `https://www.hongkong.ai/news/${article.id}`
                })}
              </script>
            </Col>
          ))}
        </Row>
        <br />
        <br />

        <Pagination className="justify-content-center my-pagination">{paginationItems}</Pagination>
        <div className="separator" />
      </Container>

      {showBackToTop && (
        <Button
          onClick={scrollToTop}
          className="back-to-top"
          variant="primary"
          aria-label="Back to top"
        >
          ↑
        </Button>
      )}
    </>
  );
};

export default News;